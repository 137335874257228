<template>
  <div class="wrapper">
    <div class="elite-content_body_inner_header">
      <div class="elite-content_body_inner_header_left">
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.13559 3.37608V2.15574C9.13559 1.40689 8.52851 0.799805 7.77966 0.799805H2.35593C1.60708 0.799805 1 1.40689 1 2.15574V15.4439C1 16.1927 1.60708 16.7998 2.35593 16.7998H7.77966C8.52851 16.7998 9.13559 16.1927 9.13559 15.4439V14.2235M5.0678 16.7998V15.4439M5.0678 0.799805V2.15574M3.98301 10.9694C4.23037 11.2987 4.6242 11.5117 5.06779 11.5117M5.06779 11.5117C5.81664 11.5117 6.42373 10.9046 6.42373 10.1557C6.42373 9.40689 5.81664 8.7998 5.06779 8.7998C4.31895 8.7998 3.71186 8.19272 3.71186 7.44387C3.71186 6.69503 4.31895 6.08794 5.06779 6.08794M5.06779 11.5117V12.8676M5.06779 6.08794C5.51135 6.08794 5.90518 6.30092 6.15257 6.63025M5.06779 6.08794V4.73201M9.92986 6.08794L8.57393 7.44387L9.92986 8.7998M9.13559 7.44387H14.5593M13.765 10.1557L15.1209 11.5117L13.765 12.8676M14.5593 11.5117H9.13559"
            stroke="#000"
            stroke-width="1.35593"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h2>{{ $t("APPS.DPA_MODULE") }}</h2>
      </div>
    </div>
    <div class="elite-content_body_inner_container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  mixins: [moduleLayoutMixin],
};
</script>
