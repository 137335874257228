import permissions from "../constants/permissions";
import EsignModuleLayout from "@/views/Pages/EsignModule/EsignModuleLayout.vue";
import EsignModuleMenu from "@/views/Pages/EsignModule/EsignModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListEsignPage = () =>
  import("@/views/Pages/EsignModule/EsignManagement/ListEsignPage.vue");

const EsignPage = () => import("@/views/Pages/EsignModule/EsignPage.vue");
  
const ListContractPage = () =>
  import(
    "@/views/Pages/EsignModule/ContractManagement/ListContractPage.vue"
  );

const ListSignaturePage = () =>
  import(
    "@/views/Pages/EsignModule/SignatureManagement/ListSignaturePage.vue"
  );

let esignManagementPages = {
  path: "packages",
  components: {
    default: EsignModuleLayout,
    SidebarSecondLevelMenu: EsignModuleMenu,
  },
  children: [
    {
      path: "",
      name: "ListEsignsPackages",
      components: { default: ListEsignPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_ESIGNS,
        hasLevel2: true,
      },
    },
  ],
};

let contractManagementPages = {
  path: "contracts",
  components: {
    default: EsignModuleLayout,
    SidebarSecondLevelMenu: EsignModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Contracts",
      components: { default: ListContractPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CONTRACTS,
        hasLevel2: true,
      },
    },
  ],
};


let signatureManagementPages = {
  path: "signatures",
  components: {
    default: EsignModuleLayout,
    SidebarSecondLevelMenu: EsignModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Signatures",
      components: { default: ListSignaturePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SIGNATURES,
        hasLevel2: true,
      },
    },
  ],
};


const esignModuleRoutes = [
  {
    path: "",
    components: {
      default: EsignModuleLayout,
      SidebarSecondLevelMenu: EsignModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Esign Page",
        components: { default: EsignPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  esignManagementPages,
  signatureManagementPages,
  contractManagementPages,
];

export default esignModuleRoutes;
