import service from "@/store/services/third-party-services-service";

export const state = {
  list : {},
  item : {},
  meta : {},
};

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, item) => {
    state.item = item;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then((item) => {
      commit("SET_RESOURCE", item);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((item) => {
      commit("SET_RESOURCE", item);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((item) => {
      commit("SET_RESOURCE", item);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  item : (state) => state.item,
  dropdown: (state) => {
    return state.list.map((item) => ({
      id: item.id,
      name: item.name,
    }));
  },
};

const thirdPartyServices = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default thirdPartyServices;
