<template>
  <div class="wrapper">
    <div class="elite-content_body_inner_header">
      <div class="elite-content_body_inner_header_left">
        <svg
          width="18"
          height="17"
          viewBox="0 0 18 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.19667 1.9248C4.00322 1.92481 3.8136 1.97869 3.64904 2.08039C3.48449 2.1821 3.35151 2.32761 3.265 2.50064L2.51125 4.00814H15.4888L14.7346 2.50064C14.6481 2.32774 14.5153 2.18231 14.3509 2.08061C14.1865 1.97891 13.9971 1.92497 13.8037 1.9248H4.19667ZM15.875 5.25814H2.125V14.6331C2.125 14.9094 2.23475 15.1744 2.4301 15.3697C2.62545 15.5651 2.8904 15.6748 3.16667 15.6748H14.8333C15.1096 15.6748 15.3746 15.5651 15.5699 15.3697C15.7653 15.1744 15.875 14.9094 15.875 14.6331V5.25814ZM2.14708 1.94147C2.33745 1.5608 2.63007 1.24066 2.99215 1.01693C3.35423 0.793211 3.77146 0.674742 4.19708 0.674805H13.8033C14.229 0.674742 14.6462 0.793211 15.0083 1.01693C15.3703 1.24066 15.663 1.5608 15.8533 1.94147L17.0592 4.35397C17.1025 4.44067 17.125 4.53624 17.125 4.63314V14.6331C17.125 15.2409 16.8836 15.8238 16.4538 16.2536C16.024 16.6834 15.4411 16.9248 14.8333 16.9248H3.16667C2.55888 16.9248 1.97598 16.6834 1.54621 16.2536C1.11644 15.8238 0.875 15.2409 0.875 14.6331V4.63314C0.875007 4.53624 0.897544 4.44067 0.940833 4.35397L2.14708 1.94147Z"
            fill="#000"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.29164 12.9666C6.29164 12.8008 6.35749 12.6418 6.4747 12.5246C6.59191 12.4074 6.75088 12.3416 6.91664 12.3416H11.0833C11.2491 12.3416 11.408 12.4074 11.5252 12.5246C11.6425 12.6418 11.7083 12.8008 11.7083 12.9666C11.7083 13.1323 11.6425 13.2913 11.5252 13.4085C11.408 13.5257 11.2491 13.5916 11.0833 13.5916H6.91664C6.75088 13.5916 6.59191 13.5257 6.4747 13.4085C6.35749 13.2913 6.29164 13.1323 6.29164 12.9666Z"
            fill="#000"
          />
        </svg>

        <h2>{{ $t("APPS.INVENTORY_MODULE") }}</h2>
      </div>
    </div>
    <div class="elite-content_body_inner_container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
