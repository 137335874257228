<template>
  <div class="elite-content_sidebar_level2">
    <div class="elite-content_sidebar_level2_inner">
      <h2>{{ $t("APPS.ESIGN_MODULE") }}</h2>
      <ul class="sub-menu-items">
        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SIGNATURES)">
          <router-link
            :to="{ name: 'List Signatures' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("SIGNATURES.SIGNATURES") }}
              </span>
            </a>
          </router-link>
        </li>
        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_CONTRACTS)">
          <router-link
            :to="{ name: 'List Contracts' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("CONTRACTS.CONTRACTS") }}
              </span>
            </a>
          </router-link>
        </li>
        <!-- <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_ESIGNS)">
          <router-link
            :to="{ name: 'ListEsignsPackages' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("ESIGNS.PACKAGES") }}
              </span>
            </a>
          </router-link>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  mixins: [moduleLayoutMixin],
};
</script>
