export const ENTITY_TYPE_CUSTOMER = "CUSTOMER";
export const ENTITY_TYPE_INVOICE = "INVOICE";
export const ENTITY_TYPE_PAYMENT = "PAYMENT";
export const REQUEST_FROM_SERVICE = "SERVICE";
export const REQUEST_FROM_APP = "APP";
export const SYNC_STATUS_PENDING = "PENDING";
export const SYNC_STATUS_SUCCESS = "SUCCESS";
export const SYNC_STATUS_FAILED = "FAILED";
export const ACTION_CREATE = "CREATE";
export const ACTION_UPDATE = "UPDATE";
export const ACTION_DELETE = "DELETE";

export const ENTITY_TYPES = [
  ENTITY_TYPE_CUSTOMER,
  ENTITY_TYPE_INVOICE,
  ENTITY_TYPE_PAYMENT,
];

export const REQUEST_FROM = [REQUEST_FROM_SERVICE, REQUEST_FROM_APP];

export const SYNC_STATUS = [
  SYNC_STATUS_PENDING,
  SYNC_STATUS_SUCCESS,
  SYNC_STATUS_FAILED,
];

export const ACTIONS = [
  ACTION_CREATE,
  ACTION_UPDATE,
  ACTION_DELETE,
];