import { REQUEST_FROM } from "../../constants/syncLogs";

export default {
  SYNC_LOGS_LIST: "Liste des journaux",
  ADD_SYNC_LOG: "Ajouter un journal",
  SYNC_LOGS: "Journaux",
  SYNC_LOG: "Journal",
  SYNC_LOG_ADDED: "Journal ajouté",
  EDIT_SYNC_LOG: "Modifier un journal",
  SYNC_LOG_UPDATED: "Journal mis à jour",
  DELETE_THIS_SYNC_LOG: "Supprimer ce journal ?",
  SYNC_LOG_DELETED: "Journal supprimé",
  SYNC_LOG_TYPE: "Type de journal",
  VIEW_SYNC_LOG: "Journal",
  ENTITY_TYPE_CUSTOMER: "Client",
  ENTITY_TYPE_INVOICE: "Facture",
  ENTITY_TYPE_PAYMENT: "Paiment",
  REQUEST_FROM_SERVICE: "Service",
  REQUEST_FROM_APP: "Application",
  SYNC_STATUS_PENDING: "En attente",
  SYNC_STATUS_SUCCESS: "Réussie",
  SYNC_STATUS_FAILED: "Echouée",
  ACTION_CREATE: "Création",
  ACTION_UPDATE: "Mise à jour",
  ACTION_DELETE: "Suppression",
  ENTITY_TYPE: "Entité à synchroniser",
  ACTION: "Action",
  REQUEST_FROM: "Initiée par ",
  REQUEST_DATA: "Données envoyés",
  RESPONSE_DATA: "Réponse de la demande",
  STATUS: "Statut",
  ATTEMPS: "Nombre d'éssaie",
  LAST_ATTEMPT_AT: "Date du dernier éssaie",
  SERVICE: "Service",
  RETRY: "Réessayer",
  RELAUNCH: "Relancer",
  RETRY_THIS_SYNC:"Relancer cette synchronisation ?",
  SYNC_RELAUNCHED:"Synchronisation relancée avec succès"
};
