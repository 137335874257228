import store from "../store";
import moment from "moment";
import { i18n } from "./i18n";

const timeZoneDateFormat = {
  install: (Vue) => {
    Vue.prototype.$timeZoneDateFormat = (value, format = "LLL") => {
      // const me = store.getters["profile/me"];
      // const timeZone = moment().utcOffset();
      // const dateTimeZone = moment().utcOffset(value).utcOffset;
      // const momentDate = moment(value).utcOffset(timeZone + dateTimeZone);
      // return momentDate.locale(me?.locale ?? "fr").format(format);

      // const momentDate = moment.tz(value, "UTC").tz(moment.tz.guess());
      const momentDate = moment.tz(value, "UTC").tz('America/Toronto');
      return momentDate.locale(i18n.locale ?? "fr").format(format);
    };
    Vue.prototype.$tzTimeAgo = (value) => {
      // const momentDate = moment.tz(value, "UTC").tz(moment.tz.guess());
      const momentDate = moment.tz(value, "UTC").tz('America/Toronto');
      return momentDate.locale(i18n.locale ?? "fr").fromNow();
    };
  },
};

export default timeZoneDateFormat;
