export default {
  CONTRACTS: "Modèles",
  CONTRACTS_LIST: "Liste des modèles",
  ADD_CONTRACT: "Ajouter un modèle",
  CONTRACT_ADDED: "Modèle ajouté",
  EDIT_CONTRACT: "Modifier un modèle",
  CONTRACT_UPDATED: "Modèle mis à jour",
  DELETE_THIS_CONTRACT: "Supprimer ce modèle ?",
  CONTRACT_DELETED: "Modèle supprimé",
  CONTRACT_TYPE: "Catégorie du modèle",
  CATEGORY: "Catégorie",
  VIEW_CONTRACT: "Modèle",
  RETURN_TO_FROM: "Retourner au formulaire",
  SAME_CONTRACTS:
    'Un ou plusieurs contracts ont déjà été enregistrés avec le nom {name}. S\'agit-il du même modèle ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
  CREATED_AT: "Créé le",
  SELECT: "Sélectionner un modèle",
  DPA: "Débit et crédit pré-autorisés",
  PPA: "Paiement préautorisé",
  OTHERS: "Autres",
  WHOLESALE_DEALER: "Modèle d'adhésion grossiste",
  DEALER: "Modèle d'adhésion concessionnaire",
  TYPE_REQUIRED: "Veuillez sélectionner une catégorie",
  FILES: "Documents",
  ADD_FILE: "Ajouter un document",
  ADD_FILES: "Ajouter les documents",
  DELETE_THIS_FILE: "Supprimer ce document ?",
  FILE_DELETED: "Document supprimé !",
  NAME_REQUIRED: "Veuillez entrer le nom du document",
  SIGNATURE_PAGE: "Page de signature",
  SIGNATURE_PAGE_REQUIRED: "Le numéro de la page de signature est requis",
  SIGNATURE_TYPE: "Type de signature",
  SIGNATURE_TYPE_REQUIRED: "Le type de signature est requis",
  EDIT_FILE: "Modifier le document",
  ADD_TEMPLATE_DOCUMENT: "Ajouter un modèle de document (Template)",
  TEMPLATE_NAME: "Modèle (vue)",
  TEMPLATE_NAME_REQUIRED: "Le nom du modèle est requis",
  FILTER_CONTRACTS: "Filtrer les modèles",
  UNDETERMINED: "Modèle indéterminé ?",
  DURATION: "Durée en mois",
  CONTRACT_REQUIRED: "Veuillez sélectionner un modèle !",
  FULLNAME: "Nom et prénom",
  INITIALS: "Initiales",
  CAPTURE: "Capture de signature",
  LABEL: "Étiquette",
  TEXTFIELD: "Champ texte court",
  TEXTAREA: "Zone de texte",
  CHECKBOX: "Case à cocher",
  DATE: "Date",
  RADIO: "Boutons radio",
  LIST: "Liste déroulante",
  QRCODE: "Code QR",
  CUSTOMFIELD: "Champ personnalisé",
  SEAL: "Pièce jointe",
  MOBILE_CAPTURE: "Capture mobile",
  RAW_CAPTURE: "Capture brute",
  DATEPICKER: "Sélecteur de date",
  DPA_CREDIT:"Dépôt Direct",
  DPA_DEBIT:"Prélèvement pré-autorisé",
  SIGNATURE_PAGES: "Pages de signature",
  SIGNATURE_PAGES_REQUIRED: "Vous devez ajouter au moins une page de signature",
  SIGNATURE_POSITION_PAGE_NUMBER:"Numéro",
  SIGNATURE_POSITION_PAGE_NUMBER_REQUIRED: "Le Numéro est requis",
  SIGNATURE_POSITION_X:"X",
  SIGNATURE_POSITION_Y:"Y",
  SIGNATURE_POSITION_WIDTH:"Largeur",
  SIGNATURE_POSITION_HEIGHT:"Hauteur",
  ADD_SIGNATURE_PAGE:"Ajouter une page"
};
