import permissions from "../constants/permissions";
import SyncModuleLayout from "@/views/Pages/SyncModule/SyncModuleLayout.vue";
import SyncModuleMenu from "@/views/Pages/SyncModule/SyncModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListSyncLogPage = () =>
  import("@/views/Pages/SyncModule/SyncLogManagement/ListSyncLogPage.vue");

const SyncPage = () => import("@/views/Pages/SyncModule/SyncPage.vue");

let syncLogManagementPages = {
  path: "sync-logs",
  components: {
    default: SyncModuleLayout,
    SidebarSecondLevelMenu: SyncModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List SyncLogs",
      components: { default: ListSyncLogPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SYNC_LOGS,
        hasLevel2: true,
      },
    },
  ],
};

const syncModuleRoutes = [
  {
    path: "",
    components: {
      default: SyncModuleLayout,
      SidebarSecondLevelMenu: SyncModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Sync Page",
        components: { default: SyncPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  syncLogManagementPages,
];

export default syncModuleRoutes;
