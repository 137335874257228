<template>
  <div class="lang">
    <base-dropdown
      title-classes="btn btn-sm"
      menu-on-right
      id="lang-switcher"
      menuClasses="lang-switcher"
    >
      <template slot="title">
        <span class="text">{{
          $i18n.locale === "fr"
            ? `${this.$t("COMMON.LOCALE_FR")}`
            : `${this.$t("COMMON.LOCALE_EN")}`
        }}</span>
        <span class="icon">
          <i class="fal fa-chevron-down"></i>
        </span>
      </template>
      <li v-for="lang in langs" :key="lang.id">
        <a
          class="dropdown-item"
          v-bind:class="{ active: lang.slug === $i18n.locale }"
          @click="selectLang(lang.slug)"
        >
          <img :src="`/img/canada-flag.svg`" />
          {{ lang.text }}
          <span class="check"><i class="fal fa-check"></i></span>
        </a>
      </li>
    </base-dropdown>
  </div>
</template>

<script>
import { loadLanguageAsync } from "../plugins/i18n";

export default {
  name: "lang-switcher",

  data: () => ({
    langs: [
      {
        id: 1,
        text: "Français",
        slug: "fr",
      },
      {
        id: 2,
        text: "Anglais",
        slug: "en",
      },
    ],
  }),

  methods: {
    selectLang(lang) {
      loadLanguageAsync(lang);
    },
  },
};
</script>
<style></style>
