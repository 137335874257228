<template>
  <div class="wrapper">
    <div class="elite-content_body_inner_header">
      <div class="elite-content_body_inner_header_left">
        <svg
          width="16"
          height="19"
          viewBox="0 0 16 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.82 10.2984V7.12169L13.8997 6.66449C13.8128 6.41056 13.7099 6.16243 13.5915 5.92162L14.6263 4.23998L12.38 1.99369L10.6984 3.02856C10.4576 2.91013 10.2095 2.80719 9.95556 2.72039L9.49836 0.800049H6.32167L5.86444 2.72039C5.61052 2.80719 5.3624 2.91013 5.1216 3.02856L3.43997 1.99369L1.19364 4.23998L2.22848 5.92162C2.11005 6.16243 2.00712 6.41056 1.9203 6.66449L0 7.12165V10.2984L1.92034 10.7556C2.00715 11.0095 2.11009 11.2577 2.22851 11.4985L1.18879 13.188L2.74632 14.6799C2.5804 15.1773 2.49589 15.6982 2.49604 16.2225V18.5536H13.3943V16.2225C13.3943 15.7126 13.3143 15.2101 13.1602 14.7307L14.6187 13.1677L13.5915 11.4985C13.7099 11.2577 13.8129 11.0096 13.8997 10.7556L15.82 10.2984ZM12.3396 17.4989H3.55071V16.2225C3.55071 15.4911 3.75911 14.7788 4.15342 14.1627C4.85927 13.0598 6.06243 12.4014 7.37187 12.4014H8.51844C9.82788 12.4014 11.031 13.0598 11.7369 14.1627C12.1308 14.7775 12.34 15.4924 12.3396 16.2225V17.4989ZM7.94516 11.3028C6.60035 11.3028 5.50624 10.2087 5.50624 8.86385C5.50624 7.51905 6.60035 6.42494 7.94516 6.42494C9.28996 6.42494 10.3841 7.51905 10.3841 8.86385C10.3841 10.2087 9.28996 11.3028 7.94516 11.3028ZM13.2992 13.0358L12.686 13.693C12.6659 13.66 12.6461 13.6268 12.6252 13.5942C12.0204 12.6491 11.1291 11.9604 10.103 11.6095C10.9159 10.9693 11.4387 9.97649 11.4387 8.86385C11.4387 6.93751 9.87147 5.37027 7.94512 5.37027C6.01877 5.37027 4.45154 6.93751 4.45154 8.86385C4.45154 9.97649 4.97437 10.9693 5.78724 11.6095C4.76115 11.9604 3.86989 12.6491 3.26504 13.5942C3.2488 13.6196 3.2335 13.6455 3.21775 13.6711L2.53327 13.0155L3.44517 11.5336L2.75542 9.87036L1.05467 9.46537V7.9547L2.75546 7.54974L3.44521 5.88646L2.52842 4.39671L3.59662 3.32851L5.08641 4.24529L6.74969 3.55554L7.15468 1.85472H8.66535L9.07031 3.55551L10.7336 4.24529L12.2233 3.32851L13.2915 4.39671L12.3748 5.88646L13.0645 7.54974L14.7653 7.9547V9.46537L13.0645 9.87032L12.3748 11.5336L13.2992 13.0358Z"
            fill="#000"
          />
        </svg>

        <h2>{{ $t("APPS.ADMIN") }}</h2>
      </div>
    </div>
    <div class="elite-content_body_inner_container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
