import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

async function list(params) {
  let options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return await axios.get(`${url}/third-party-services`, options)
                    .then((response) => ({
                      list: jsona.deserialize(response.data),
                      meta: response.data.meta,
                    }));
}

async function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios
                        .get(
                          `${url}/third-party-services/${id}?include=organization,reseller`,
                          options
                        );
  let service = jsona.deserialize(response.data);
  delete service.links;
  return service;
}

async function add(service) {
  const payload = jsona.serialize({
    stuff: service,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios
                        .post(
                          `${url}/third-party-services?include=organization,reseller`,
                          payload,
                          options
                        );
  return jsona.deserialize(response.data);
}

async function update(service) {
  const payload = jsona.serialize({
                    stuff: service,
                    includeNames: [],
                  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const response = await axios
                    .patch(
                      `${url}/third-party-services/${service.id}?include=organization,reseller`,
                      payload,
                      options
                    );
  return jsona.deserialize(response.data);
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/third-party-services/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
