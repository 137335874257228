import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/contracts`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  // Vérifier si l'id contient le flag include_edition_url
  let contractId = id;
  let includeParam = 'include=files,organization';
  if (contractId.includes('include_edition_url')) {
    includeParam += '&include_edition_url=1';
    contractId = contractId.replace('_include_edition_url', '');
  }else if (contractId.includes('include_fields')) {
    includeParam += '&include_fields=1';
    contractId = contractId.replace('_include_fields', '');
  }

  return axios
    .get(
      `${url}/contracts/${contractId}?${includeParam}`, 
      options
    )
    .then((response) => {
      let contract = jsona.deserialize(response.data);
      delete contract.links;
      return contract;
    });
}

function add(contract) {
  const payload = jsona.serialize({
    stuff: contract,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/contracts?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(contract) {
  const payload = jsona.serialize({
    stuff: contract,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/contracts/${contract.id}?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/contracts/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
