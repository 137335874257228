export default {
  SIGNATURES: "Signatures",
  SIGNATURE: "Signature",
  SIGNATURES_LIST: "Liste des signatures",
  ADD_SIGNATURE: "Ajouter une signature",
  SIGNATURE_ADDED: "Signature ajoutée",
  EDIT_SIGNATURE: "Modifier une signature",
  SIGNATURE_UPDATED: "Signature mise à jour",
  DELETE_THIS_SIGNATURE: "Supprimer cette signature ?",
  SIGNATURE_DELETED: "Signature supprimée",
  SIGNATURE_TYPE: "Catégorie de la signature",
  CATEGORY: "Catégorie",
  VIEW_SIGNATURE: "Signature",
  RETURN_TO_FROM: "Retourner au formulaire",
  SAME_SIGNATURES:
    'Une ou plusieures signatures ont déjà été enregistrées avec le nom "{name}". S\'agit-il de la même signature ?.',
  CREATED_AT: "Créée le",
  SELECT: "Sélectionner une signature",
  DPA: "Dépôt directe",
  PPA: "Paiement préautorisé",
  OTHERS: "Autres",
  TYPE_REQUIRED: "Veuillez sélectionner une catégorie",
  FILES: "Documents",
  FILES_TO_BE_SIGN: "Documents à signer",
  SIGNED_FILES: "Documents signés",
  CONTRACT_REQUIRED: "Veuillez sélectionner un contrat",
  SELECT_CONTRACT: "Sélectionnez un contrat",
  CREATED: "Créee",
  UPDATED: "Mise à jour",
  DELETED: "Supprimée",
  DRAFT: "Brouillon",
  SENT: "Envoyée",
  SENTS: "Envoyées",
  COMPLETED: "Complétée",
  DECLINED: "Refusée",
  EXPIRED: "Expirée",
  SIGNERS: "Signataires",
  SIGNERS_REQUIERED: "Veuillez ajouter au moin un signataire",
  SEND: "Envoyer",
  RESEND: "Renvoyer",
  SEND_FOR_SIGN_MESSAGE: "Confirmez-vous vouloir envoyer cette demande aux différents signataires ?",
  SIGNATURE_SENT: "Demande de signature envoyée !",
  SIGNERS_EMAIL_MESSAGE:"Un méssage à pour accompagner l'e-mail de signature ?",
  EMAIL_MESSAGE_TITLE: "E-mail de signature",
  RESEND_SIGNATURE_EMAIL: "Renvoyer l'e-mail de signature",
  VIEW_DETAILS:"Plus de détails",
  GET_DOCUMENTS:"Charger les documents signés",
  DOCUMENTS_CHARGED: "Documents chargés avec succès !",
  ADD_SIGNER:"Ajouter un signataire",
  FROM_MODEL: "A partir d'un modèle",
  SELECT_MODEL: "Sélectionnez un modèle",
  MODEL_REQUIRED: "Veuillez sélectionner un modèle",
  ROLES: "Rôle des différents signataires",
  ADD_ROLES: "Saisissez pour ajouter des rôles",
  ROLES_REQUIRED: "Veuillez ajouter les différents rôles",
  SEND_FOR_SIGNATURE: "Envoyer immédiatement pour signature",
  DOCUMENT: "Document",
  ROLE: "Rôle",
  DOCUMENTS_PAYLOAD:"Champs à remplir pour les documents",
  EMAIL_MESSAGE: "Message à tous les destinataires (facultatif)",
  EDIT_TRANSACTION: "Gérer",
  EDITOR: "Editeur",
  CANNOT_EDIT: "Impossible de modifier cette signature",
  CANNOT_DELETE: "Impossible de supprimer cette signature",
  CALL_BACK_URL:"URL de rappel",
  CLONE_MODEL:"Clôner un modèle",
};
