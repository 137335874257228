<template>
  <div class="wrapper">
    <div class="elite-content_body_inner_header">
      <div class="elite-content_body_inner_header_left">
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.0926 16.1363L11.2779 17.3218C11.3832 17.4235 11.5243 17.4798 11.6707 17.4785C11.817 17.4772 11.9571 17.4185 12.0606 17.315C12.1641 17.2115 12.2228 17.0714 12.2241 16.925C12.2254 16.7786 12.1691 16.6375 12.0674 16.5322L10.9301 15.3948C11.4116 14.7998 11.6738 14.0573 11.6727 13.2919C11.6727 11.443 10.1713 9.94153 8.32266 9.94153C6.47346 9.94153 4.97266 11.443 4.97266 13.2919C4.97304 13.8892 5.13305 14.4756 5.43612 14.9903C5.73919 15.5051 6.17431 15.9294 6.69644 16.2195C7.21857 16.5096 7.80873 16.6548 8.40583 16.6402C9.00293 16.6256 9.58527 16.4516 10.0926 16.1363ZM8.32266 11.0583C9.55546 11.0583 10.556 12.0595 10.556 13.2919C10.556 14.5248 9.55546 15.5254 8.32266 15.5254C7.08986 15.5254 6.08933 14.5248 6.08933 13.2919C6.08933 12.0595 7.08986 11.0583 8.32266 11.0583Z"
            fill="#000"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.11667 7.48638L15.6333 7.49978V11.4616C15.6325 11.5269 15.6185 11.5914 15.5921 11.6512C15.5658 11.711 15.5275 11.7648 15.4798 11.8094C15.37 11.9118 15.2251 11.9682 15.075 11.9669H13.4329C13.2892 11.9733 13.1534 12.035 13.054 12.139C12.9545 12.243 12.899 12.3814 12.899 12.5253C12.899 12.6692 12.9545 12.8076 13.054 12.9116C13.1534 13.0156 13.2892 13.0773 13.4329 13.0837H15.075C15.5161 13.0837 15.9382 12.9151 16.2497 12.618C16.4072 12.4693 16.5328 12.29 16.6188 12.0912C16.7048 11.8924 16.7494 11.6782 16.75 11.4616V4.54868C16.75 4.11537 16.5713 3.69825 16.2492 3.39225C15.9317 3.09218 15.5112 2.92548 15.0744 2.92655L8.9378 2.92879C8.75913 2.92879 8.5894 2.85229 8.4822 2.71604L7.48055 1.43956C7.32112 1.23901 7.11833 1.07719 6.88741 0.966263C6.65648 0.855334 6.40343 0.798176 6.14725 0.799083H1.675C0.742583 0.799083 0 1.53281 0 2.42121V11.4616C0 12.35 0.742583 13.0837 1.675 13.0837H3.33939C3.48747 13.0837 3.62949 13.0249 3.73419 12.9201C3.8389 12.8154 3.89773 12.6734 3.89773 12.5253C3.89773 12.3772 3.8389 12.2352 3.73419 12.1305C3.62949 12.0257 3.48747 11.9669 3.33939 11.9669H1.675C1.37406 11.9669 1.11667 11.7486 1.11667 11.4616V7.48638ZM15.6333 6.38299V4.54868C15.6326 4.48328 15.6185 4.41871 15.592 4.35891C15.5655 4.2991 15.5272 4.24531 15.4792 4.2008C15.3697 4.09835 15.225 4.04197 15.075 4.04334L8.9378 4.04557C8.68151 4.04668 8.4283 3.98962 8.19725 3.87868C7.96621 3.76774 7.76334 3.60581 7.60394 3.40509L6.60229 2.12917C6.54745 2.06158 6.47803 2.00729 6.39922 1.97035C6.32042 1.93341 6.23428 1.91478 6.14725 1.91587H1.675C1.37406 1.91587 1.11667 2.1342 1.11667 2.42121V6.36959L15.6333 6.38299Z"
            fill="#000"
          />
        </svg>
        <h2>{{ $t("APPS.FILE_EXPLORER_MODULE") }}</h2>
      </div>
      <div class="elite-content_body_inner_header_right">
        <button><i class="far fa-ellipsis-h"></i></button>
      </div>
    </div>
    <div class="elite-content_body_inner_container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
