<template>
  <div class="wrapper">
    <div class="elite-content_body_inner_header">
      <div class="elite-content_body_inner_header_left">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.4496 8.92311C4.73571 8.92311 4.15497 9.50395 4.15497 10.2177C4.15497 10.9316 4.73571 11.5123 5.4496 11.5123C6.16336 11.5123 6.74423 10.9316 6.74423 10.2177C6.74423 9.50395 6.16336 8.92311 5.4496 8.92311ZM5.4496 10.5056C5.2909 10.5056 5.16173 10.3765 5.16173 10.2177C5.16173 10.059 5.2909 9.92994 5.4496 9.92994C5.6083 9.92994 5.73737 10.059 5.73737 10.2177C5.73737 10.3765 5.6083 10.5056 5.4496 10.5056ZM9.7129 8.92311C8.99902 8.92311 8.41827 9.50395 8.41827 10.2177C8.41827 10.9316 8.99902 11.5123 9.7129 11.5123C10.4268 11.5123 11.0075 10.9316 11.0075 10.2177C11.0075 9.50395 10.4268 8.92311 9.7129 8.92311ZM9.7129 10.5056C9.55421 10.5056 9.425 10.3765 9.425 10.2177C9.425 10.059 9.55424 9.92994 9.7129 9.92994C9.87157 9.92994 10.0008 10.059 10.0008 10.2177C10.0008 10.3765 9.8716 10.5056 9.7129 10.5056ZM7.5812 6.90899C7.3032 6.90899 7.0779 7.13432 7.0779 7.41226V13.0232C7.0779 13.3012 7.30323 13.5265 7.58117 13.5265C7.85911 13.5265 8.08463 13.3012 8.08463 13.0233V7.41226C8.08463 7.13425 7.8593 6.90899 7.5812 6.90899Z"
            fill="#000"
          />
          <path
            d="M17.1853 0.613945C16.9887 0.417357 16.6699 0.417487 16.4733 0.613945L14.6792 2.4082C13.9002 1.78676 12.9414 1.44951 11.9304 1.44951H11.9206C10.7437 1.45205 9.63716 1.91177 8.80501 2.74389L0.991415 10.5575C0.557549 10.9914 0.557549 11.6974 0.991415 12.1312L5.66785 16.8078C5.87817 17.0178 6.15754 17.1337 6.45472 17.1337C6.75202 17.1337 7.03152 17.018 7.24172 16.8078L15.0552 8.99419C15.8875 8.16204 16.3471 7.0555 16.3497 5.87864C16.3518 4.86403 16.0144 3.90157 15.391 3.12004L17.1851 1.32581C17.3818 1.12932 17.3818 0.810532 17.1853 0.613945ZM15.343 5.87646C15.3409 6.78527 14.9859 7.63969 14.3433 8.28235L6.52979 16.0959C6.51998 16.1058 6.50829 16.1137 6.4954 16.119C6.48252 16.1243 6.46869 16.127 6.45475 16.1269C6.44082 16.127 6.42702 16.1243 6.41416 16.119C6.4013 16.1136 6.38964 16.1057 6.37988 16.0958L1.70335 11.4194C1.68345 11.3995 1.67226 11.3725 1.67223 11.3444C1.67221 11.3162 1.68335 11.2892 1.70322 11.2693L9.51688 3.45589C10.1594 2.81322 11.0139 2.4582 11.9227 2.45628H11.9304C12.6719 2.45628 13.3771 2.69115 13.9615 3.12577L13.0423 4.04503C12.4166 3.71605 11.6083 3.80264 11.0638 4.34715C10.4055 5.0056 10.4055 6.07692 11.0638 6.73534C11.3828 7.05439 11.8069 7.23001 12.2579 7.23001C12.709 7.23001 13.1332 7.05426 13.4521 6.73534C13.771 6.41642 13.9468 5.99235 13.9468 5.5412C13.9468 5.26345 13.8799 4.99593 13.7541 4.75687L14.6733 3.83776C15.1095 4.42408 15.3445 5.13204 15.343 5.87646ZM12.7402 5.05896C12.8038 5.12213 12.8541 5.19728 12.8884 5.28005C12.9227 5.36282 12.9403 5.45157 12.94 5.54116C12.94 5.72336 12.8691 5.89469 12.7402 6.02347C12.677 6.08695 12.6019 6.13728 12.5191 6.17155C12.4364 6.20582 12.3476 6.22334 12.2581 6.22312C12.0758 6.22312 11.9045 6.15215 11.7757 6.02347C11.5098 5.75745 11.5098 5.32488 11.7757 5.05896C12.0423 4.79245 12.4736 4.79245 12.7402 5.05896Z"
            fill="#000"
          />
        </svg>

        <h2>{{ $t("APPS.SALES_MODULE") }}</h2>
      </div>
    </div>
    <div class="elite-content_body_inner_container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
