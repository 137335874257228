<template>
  <div class="wrapper">
    <div class="elite-content_body_inner_header">
      <div class="elite-content_body_inner_header_left">
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.9602 15.4086H4.04648C2.85835 15.4086 1.74734 14.762 1.14703 13.7209C0.39662 12.4197 0 10.9367 0 9.43245C0 5.71805 2.32218 2.36836 5.87344 1.21353C7.03465 0.835896 8.25592 0.715974 9.50331 0.857079C13.2907 1.28544 16.3347 4.30388 16.9016 8.12367C17.2049 10.1671 16.8038 12.167 15.7414 13.9073C15.1761 14.8334 14.1104 15.4086 12.9602 15.4086ZM8.49775 2.12808C4.41189 2.12808 1.32804 5.47568 1.32804 9.43245C1.32804 10.7042 1.66328 11.9577 2.29748 13.0575C2.66137 13.6886 3.33156 14.0806 4.04648 14.0806H12.9602C13.6508 14.0806 14.2821 13.749 14.608 13.2153C15.5062 11.7438 15.8451 10.0506 15.588 8.31862C15.1048 5.06283 12.5413 2.53719 9.35408 2.17669C9.06977 2.14443 8.78388 2.1282 8.49775 2.12808Z"
            fill="#000"
          />
          <path
            d="M13.679 10.0963C13.3123 10.0963 13.015 9.79899 13.015 9.43225C13.015 6.82583 11.0316 4.7841 8.49964 4.7841C5.96766 4.7841 3.98429 6.82583 3.98429 9.43225C3.98429 9.79899 3.68701 10.0963 3.32027 10.0963C2.95353 10.0963 2.65625 9.79899 2.65625 9.43225C2.65625 6.1136 5.21443 3.45605 8.49964 3.45605C11.7791 3.45605 14.343 6.10746 14.343 9.43225C14.343 9.79899 14.0458 10.0963 13.679 10.0963Z"
            fill="#000"
          />
          <path
            d="M7.9232 10.4308L9.78356 7.2085C10.2225 6.44829 11.3733 7.11115 10.9337 7.87252L9.07332 11.0948C8.634 11.8558 7.48382 11.1917 7.9232 10.4308Z"
            fill="#000"
          />
        </svg>
        <h2>{{ $t("APPS.DASHBOARD") }}</h2>
      </div>
    </div>
    <div class="elite-content_body_inner_container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},
  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
