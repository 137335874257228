export default {
  SERVICES_LIST: "Liste des services tiers",
  SERVICE_PREVIEW: "Service Tier",
  ADD_SERVICE: "Ajouter un service tier",
  ADD_THIS_SERVICE: "Ajouter ce service tier",
  SERVICE_ADDED: "Service tier ajouté",
  EDIT_SERVICE: "Modifier un Service tier",
  EDIT_THIS_SERVICE: "Modifier ce Service tier",
  SERVICE_UPDATED: "Service tier mise à jour",
  DELETE_THIS_SERVICE : "Supprimer ce Service tier?",
  SERVICE_DELETED: "Service tier supprimé",
  SERVICE_NAME: "Nom",
  SERVICE_TYPE: "Type",
  SERVICE_MODE: "Mode",
  SERVICE_ACTIVE: "Actif?",
  SERVICE_TEST_CONFIG: "Configurations Test",
  SERVICE_PROD_CONFIG: "Configurations Prod",
  RESULTS_ON_X_LINES: "Resultats pour un total de {linesCount} lignes",
  SUCCESSES: "Succes",
  FAILURES: "Echecs",
  X_ROW: "Ligne {line}",
  LINE: "Ligne",
  DATA: "Donnee",
  ERROR: "Erreur",
  MODEL_ID: "ID",
  RESULTS: "Resultats",
  IMPORTED_ITEMS: "Objets importés",
  ITEM: "Objet",
};
